import React from 'react';
import '../style.css';

import twitter from '../images/twitter.svg';
import youtube from '../images/youtube.svg';
import instagram from '../images/instagram.svg';
import facebook from '../images/facebook.svg';


const Footer = () => {
    return (
        <div>
            <div className="footer">
                <footer id='footer1'>
                    <h4>Darnell Eaton &copy; 2022</h4>
                    <ul>
                        <li><a href="https://twitter.com/darnelleaton3"><img src={twitter} alt="" /></a></li>
                        <li><a href="https://www.youtube.com/channel/UCM96imod8seBv4ZH0Dzl9WQ"><img src={youtube} alt="" /></a></li>
                        <li><a href="https://www.instagram.com/darnellde/?hl=en"><img src={instagram} alt="" /></a></li>
                        <li><a href="https://www.facebook.com/DarnellEatonREALTOR/"><img src={facebook} alt="" /></a></li>
                    </ul>
                </footer>
            </div>
        </div>
    )
}



export default Footer;
