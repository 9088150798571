//import '../NavNew.css'
import '../NavUpdate.css'
import { MenuItems } from './MenuItems';
import { useState, useEffect } from 'react';
//import '../style.css';
import styled from 'styled-components';
import { Link, NavLink } from 'react-router-dom';
import { Component } from 'react';
import Media from 'react-media';
import './ShowNameMobile';
import ShowNameMobile from './ShowNameMobile';


const NavUpdate = () => {
    // state={ clicked: false};
    const [mobileNav, setMobileNav] = useState(false);
    const [colorNav, setColorNav] = useState(false);
    const [ mobileLogo, setMobileLogo] = useState('Darnell');

    //mobile view Logo name only
    // const mobileName = () => {
    //   if(window.width <800) {
    //     setMobileLogo(true);
    //   } else {
    //     setMobileLogo(false);
    //   }
    // }
  
   
  //colored Nav function
  const changeBackground = () => {
    if(window.scrollY >= 100) {
      setColorNav(true);
    } else {
      setColorNav(false);
    }
  }

  window.addEventListener("scroll", setColorNav)

  useEffect(() => {
    let handler = () => {
      setMobileNav(false);
    };

    document.addEventListener("mousedown", handler);
  });

   const handleClick = () => {
        // this.setState({clicked:
        // !this.state.clicked})
     mobileNav(!setMobileNav);

     
    }
   
  return (
      <nav className= {colorNav ? 'nav-color-change' : 'nav'} >
        <ShowNameMobile />
      {/* <div className='nav'> */}
        {/* <Media className='#mobile'
          query = '(max-width: 600px)'>
          {matches => {
            return matches ? mobileLogo : ""
          }}
        </Media> */}
    
        {/* </div> */}
            <div>
              <ul id='navbar' className={mobileNav ? "#navbar active" :"#navbar"}>
                  {MenuItems.map((item, index) => {
                      return (
                          <li key={index}>
                              <StyledNavLink

                                  className={item.cName}
                                  to={item.url}
                              >
                                  {item.title}
                              </StyledNavLink >
                          </li>
                      )
                  })}
              </ul>
            </div>
          <div id='mobile' onClick={setMobileNav}>
                <i id='bar'
                   className={mobileNav ? 'fas fa-times' : 'fas fa-bars'}></i>
            </div>
      </nav>
  )
    
}

const StyledNavLink = styled(NavLink)`
   
 
      

  font-weight: bold;

  text-decoration: none;
  &:hover,
  &:focus {
    color: palevioletred;
  }
  &:active {
    color: #00FFFF;
  }
`;

export default NavUpdate