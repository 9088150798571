import { useState, useEffect } from "react";
import '../NavUpdate.css'

const ShowNameMobile = () => {
    const [showLogo, setShowLogo] = useState(window.innerWidth < 760);

    const mobileName = () => {
        setShowLogo(window.innerWidth < 760);
    };

    useEffect(() => {
        window.addEventListener("resize", mobileName);
        return () => window.removeEventListener("resize", mobileName);
    });

    return (
        <div className="">
            {showLogo ? (

                <h1 
                    style={{fontWeight: 'bold',
                            fontSize: '2.5rem',
                            marginLeft: '3rem'
                            }}
                    className="logo-nav">Darnell Eaton</h1>
            ) : (
                <div></div>
            )}
        </div>
    );
}

export default ShowNameMobile;