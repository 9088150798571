//cName means class Name

import styled from "styled-components";

export const MenuItems = [
    {
        title: 'Darnell Eaton',
        url: '/',
        cName: 'logo'
    },
    {
        title: 'Home',
        url: '/',
        cName: 'logo-style'
    },
    {
        title: 'My Work',
        url: '/work',
        cName: 'logo-style'
    },
    {
        title: 'Reels',
        url: '/reels',
        cName: 'logo-style'
    },
    {
        title: 'Gallery',
        url: '/gallery',
        cName: 'logo-style'
    },
    {
        title: 'Contact Me',
        url: '/contact',
        cName: 'logo-style'
    },
 
]
 