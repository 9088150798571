

export default [
    {
         id: 1,
         source: 'https://www.youtube.com/watch?v=kmObRimRJ5U'
    },

    {
        id: 2,
        source: 'https://www.youtube.com/watch?v=7aDVe1Cpae0'
    },
    {
        id: 3,
        source: 'https://www.youtube.com/watch?v=Rd6SAIIQBfc'
    },
    {
        id: 4,
        source: 'https://www.youtube.com/watch?v=iM_iFQHt-_A'
    },
    {
        id: 5,
        source: 'https://www.youtube.com/watch?v=fEg44HwsCOM'
    },
]