// import professional1 from './assets/professional1.JPG'
// import professional2 from './assets/professional2.jpg'
// import professional3 from './assets/professional3.jpg'
// import professional4 from './assets/professional4.jpg'
// import professional5 from './assets/professional5.jpg'
import improv1 from './assets/improv1.jpg'
import improv2 from './assets/improv2.jpg'
import improv3 from './assets/improv3.jpg'
import improv4 from './assets/improv 4.jpg'
import improv5 from './assets/improv 5.jpg'

import improv8 from './assets/improv 8.jpg'
import improv10 from './assets/improv 10.jpg'
import improv11 from './assets/improv 11.jpg'
import improv12 from './assets/improv 12.jpg'
import improv13 from './assets/improv 13.jpg'
import improv14 from './assets/improv 14.jpg'
import improv15 from './assets/improv 15.jpg'
import improv16 from './assets/improv 16.jpg'
import improv17 from './assets/improv 17.jpg'
import improv18 from './assets/improv 18.jpg'
import improv19 from './assets/improv 19.jpg'
import improv20 from './assets/improv 20.jpg'
import improv21 from './assets/improv 21.jpg'
import improv22 from './assets/improv 22.jpg'
import improv23 from './assets/improv 23.jpg'
import improv25 from './assets/improv 25.jpg'
import improv26 from './assets/improv 26.jpg'
import improv27 from './assets/improv 27.jpg'
import improv28 from './assets/improv 28.jpg'
import improv29 from './assets/improv 29.jpg'
import improv30 from './assets/improv 30.jpg'
import improv31 from './assets/improv 31.jpg'
import improv32 from './assets/improv 32.jpg'
import improv33 from './assets/improv 33.jpg'




export default [
                // professional1, 
                // professional2, 
                // professional3, 
                // professional4, 
                // professional5, 
             
                
                
                improv4, 
                improv5,
               
                improv8,
                improv10,
                improv11,
                improv12,
                improv13,
                improv14,
                improv15,
                improv16,
                improv17,
                improv18,
                improv19,
                improv20,
                improv21,
                improv22,
                improv23,
                
                improv25,
                improv26,
                improv27,
                improv28,
                improv29,
                improv30,
                improv31,
               
               ]